import React from "react";
import Head from "../../components/head.js";

import Layout from "../../components/layout";

import "./about.css";

const AboutPage = () => {
  return (
    <Layout>
      <Head title="About" />

      <p>
        I am an artist and web developer based out of Berkeley, CA. Feel free to
        contact me if you are interesting in the art or have any questions or
        ideas.
      </p>
      <p style={{ marginBottom: 10 }}>Social Links:</p>

      <div class="socialLinks">
        <p>
          <a href="https://www.facebook.com/benjifriedman.art">Facebook</a>
        </p>
        <p>
          <a href="https://twitter.com/benjifri">Twitter</a>
        </p>
        <p>
          <a href="https://ello.co/benjifriedman">Ello</a>
        </p>
        <p>
          <a href="https://github.com/benjifriedman/">Github</a>
        </p>
        <p>
          <a href="https://instagram.com/benjifriedman">Instagram</a>
        </p>
        <p>
          <a href="https://opensea.io/benjifriedman?tab=created">Opensea</a>
        </p>
      </div>
    </Layout>
  );
};

export default AboutPage;
